.gallery {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.2rem;
  cursor: pointer;
  padding: 1rem;
  margin-top: 5rem;
}

.gallery .pics:hover {
  filter: opacity(0.9);
}

@media (max-width: 1200px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
  }
}

@media (max-width: 991px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*model*/

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}